export default [
  {
    title: 'website_manager',
    icon: 'GlobeIcon',
    children: [
      {
        title: 'Quản lý khai giảng',
        route: 'open-list',
        action: 'view',
        resource: 'website',
      },
    ],
  },
]
