export default [
  {
    title: 'account_management',
    icon: 'UsersIcon',
    children: [
      {
        title: 'manage_registered_student',
        route: 'accounts-list',
        action: 'view',
        resource: 'user',
      },
      {
        title: 'manage_account_registration',
        route: 'contacts-list',
        action: 'view',
        resource: 'user',
      },
    ],
  },
]
