export default [
  {
    title: 'course_manager',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'course_manager',
        route: 'course-list',
        action: 'view',
        resource: 'course',
      },
    ],
  },
]
