export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Tổng quan',
        action: 'view',
        resource: 'dashboard',
        route: 'dashboard',

      },
    ],
  },
]
