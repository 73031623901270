export default [
  {
    title: 'system_managemer',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'permission',
        route: 'roles-list',
        action: 'view',
        resource: 'permission',
      },
      {
        title: 'admin_account',
        route: 'users-list',
        action: 'view',
        resource: 'permission',
      },
      {
        title: 'Lịch sử hoạt động',
        route: 'user-activity',
        action: 'view',
        resource: 'permission',
      },
    ],
  },
]
