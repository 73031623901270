export default [
  {
    title: 'general_manager',
    icon: 'LoaderIcon',
    children: [
      {
        title: 'level_manager',
        route: 'level-list',
        action: 'view',
        resource: 'general',
      },
      // {
      //   title: 'tag_manager',
      //   route: 'tag-list',
      //   action: 'view',
      //   resource: 'general',
      // },
    ],
  },
]
