export default [
  {
    title: 'capacity_manager',
    icon: 'AwardIcon',
    children: [
      {
        title: 'exam_manager',
        route: 'exam-list',
        action: 'view',
        resource: 'capacity',
      },
      {
        title: 'skill_manager',
        route: 'skill-list',
        action: 'view',
        resource: 'capacity',
      },
      {
        title: 'bank_questions',
        route: 'bank-questions-list',
        action: 'view',
        resource: 'capacity',
      },
      {
        title: 'review_manager',
        route: 'review-list',
        action: 'view',
        resource: 'capacity',
      },
      {
        title: 'Quản lý nhãn câu hỏi',
        route: 'tag-question',
        action: 'view',
        resource: 'capacity',
      },
      {
        title: 'Kết quả thi & bài tập',
        route: 'exam-result',
        action: 'view',
        resource: 'capacity',
      },
    ],
  },
]
