// import dashboard from './dashboard'
import account from './account'
import users from './users'
import capacityManager from './capacity'
import course from './course'
import content from './content'
import general from './general'
import website from './website'
import dashboard from './dashboard'

// Array of sections
export default [...dashboard, ...account, ...course, ...capacityManager, ...general, ...website, ...users]
